<template>
    <form :class="{ 'puff-out-center': isLogged }" class="grid grid-rows-2 gap-4" @submit.prevent="login">
        <div class="grid gap-2 grid-cols-2">
            <input class="border-solid border-2 border-custom_organization-900 rounded-l-2xl p-1" :placeholder="$t('Login')" type="text" v-model="username" />
            <input class="border-solid border-2 border-custom_organization-900 rounded-r-2xl p-1" :placeholder="$t('Password')" type="password" v-model="password" />
        </div>
        <div class="grid place-content-center">
            <input class="rounded-full bg-custom_organization-700 text-light py-2 px-3" :value="$t('Log in')" type="submit"/>
            <!-- <button class="rounded-full" @click="logout">logout</button> -->
        </div>
    </form>
</template>

<script>
export default {
    name: 'LoginForm',
    emits: [
        'login',
        'logout'
    ],
    props: ['isLogged'],
    data() {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        login() {
            this.$emit('login', this.username, this.password)
        },
        logout() {
            this.$emit('logout')
        }
    }
}
</script>

<style scoped>
.puff-out-center {
	-webkit-animation: puff-out-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: puff-out-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
</style>
